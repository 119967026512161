import React from "react";
import { useNavigate } from "react-router-dom";
import { FaPhone, FaSignInAlt, FaBars, FaTimes } from "react-icons/fa";
import "./Features.css";
import logo from "../assets/logo.jpeg"; 

const Features = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div>
      {/* Header */}
      <header className="header">
        <div className="logo-container">
          <img src={logo} alt="Elite Academy Logo" className="logo" />
          <span className="logo-text">Elite Academy</span>
        </div>

        <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <nav className={`nav-links ${menuOpen ? "open" : ""}`}>
          <a href="/">Home</a>
          <a href="/about">About Us</a>
          <a href="/features">Features</a>
          <a href="/contact">Contact Us</a>
        </nav>

        <div className="header-right">
          <a href="tel:+917330643345" className="phone-btn" >
            <FaPhone /> +91 7330643345
          </a>
          <button className="login-btn" onClick={handleLoginClick}>
            LOGIN
          </button>
        </div>
      </header>

      {/* Contact Us Content */}
      <section className="contact-section">
        <h1>Features</h1>
        
        <p>Elite Academy is the premier destination for all your educational needs, offering a comprehensive range of resources and services tailored to students of all ages and

academic pursuits.Whether you're seeking School Books, Competitive Books,

General Books, or Stationery,Elite Academy is your one-stop shop for fulfilling your daily

academic requirements.At Elite Academy, our mission is to empower students by

providing them with the widest selection of educational products, all conveniently

accessible from the comfort of your home. With a legacy of over 50 years in the books

trade and publishing industry, we bring unparalleled expertise and dedication to every

aspect of our service.</p>
      </section>
      {/* Footer */}
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Elite Academy. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Features;
