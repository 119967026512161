import React from "react";
import { useNavigate } from "react-router-dom";
import { FaPhone, FaSignInAlt, FaBars, FaTimes } from "react-icons/fa";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import "./Home.css";
import logo from "../assets/logo.jpeg";

const Home = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleAboutClick = () => {
    navigate("/about");
  };

  return (
    <div>
      {/* Header */}
      <header className="header">
        <div className="logo-container">
          <img src={logo} alt="Elite Academy Logo" className="logo" />
          <span className="logo-text">Elite Academy</span>
        </div>

        <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <nav className={`nav-links ${menuOpen ? "open" : ""}`}>
          <a href="/">HOME</a>
          <a href="/about">ABOUT US</a>
          <a href="/features">FEATURES</a>
          <a href="/contact">CONTACT US</a>
        </nav>

        <div className="header-right">
          <a href="tel:+917330643345" className="phone-btn" tabIndex={-1}>
            <FaPhone /> +91 7330643345
          </a>
          <button className="login-btn" onClick={handleLoginClick}>
             LOGIN
          </button>
        </div>
      </header>

      {/* Hero Section */}
      <main className="hero-section">
        <div className="hero-content">
          <h1>Welcome to Elite Academy</h1>
          <p>Your trusted platform for learning and excellence.</p>
        </div>
      </main>

      {/* FIX: Wrap in a div with margin & full width */}
      <div style={{ width: "100%", padding: "20px 0", backgroundColor: "#fffde7" }}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#f57f17" }}
          >
            Our Programmes
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {/* Prime Program */}
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 345, boxShadow: 3 }}>
                <CardMedia
                  component="img"
                  height="200"
                  image="https://images.unsplash.com/photo-1534081333815-ae5019106622?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                  alt="Prime Program"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    Prime Program
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Our flagship programme offering a comprehensive and in-depth learning experience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" variant="contained" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            {/* Techno Unique Program */}
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 345, boxShadow: 3 }}>
                <CardMedia
                  component="img"
                  height="200"
                  image="https://images.unsplash.com/photo-1524499982521-1ffd58dd89ea?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                  alt="Techno Unique Program"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    Techno Unique Program
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Blending technology with education for a futuristic learning approach.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" variant="contained" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            {/* Olympiad Programme */}
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ maxWidth: 345, boxShadow: 3 }}>
                <CardMedia
                  component="img"
                  height="200"
                  image="https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
                  alt="Olympiad Programme"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    Olympiad Programme
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Tailored for competitive exams, enhancing analytical and problem-solving skills.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" variant="contained" color="primary">
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {/* Footer */}
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Elite Academy. All Rights Reserved.</p>
      </footer>
      </div>
    </div>
  );
};

export default Home;
