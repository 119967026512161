import React from "react";
import { useNavigate } from "react-router-dom";
import { FaPhone, FaSignInAlt, FaBars, FaTimes } from "react-icons/fa";
import "./Contact.css";
import logo from "../assets/logo.jpeg"; 

const Contact = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div>
      {/* Header */}
      <header className="header">
        <div className="logo-container">
          <img src={logo} alt="Elite Academy Logo" className="logo" />
          <span className="logo-text">Elite Academy</span>
        </div>

        <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <nav className={`nav-links ${menuOpen ? "open" : ""}`}>
          <a href="/">Home</a>
          <a href="/about">About Us</a>
          <a href="/features">Features</a>
          <a href="/contact">Contact Us</a>
        </nav>

        <div className="header-right">
          <a href="tel:+917330643345" className="phone-btn">
            <FaPhone /> +91 7330643345
          </a>
          <button className="login-btn" onClick={handleLoginClick}>
            <FaSignInAlt /> Login
          </button>
        </div>
      </header>

      {/* Contact Us Content */}
      <section className="contact-section">
        <h1>Contact Us</h1>
        <p>Email: contact@eliteacademy.com</p>
        <p>Phone: +91 7330643345</p>
      </section>
      {/* Footer */}
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Elite Academy. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Contact;
