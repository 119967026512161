import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPhone, FaSignInAlt, FaBars, FaTimes } from "react-icons/fa";
import axios from "axios"; // Import Axios for API calls
import "./Login.css"; // Uses your provided styles
import logo from "../assets/logo.jpeg";

const Login = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState(""); // For handling login errors

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("http://localhost:5000/api/login", formData);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("role", response.data.role);

      // Redirect based on user role
      if (response.data.role === "admin") navigate("/dashboard");
      else if (response.data.role === "college_admin") navigate("/college-dashboard");
      else navigate("/student-dashboard");
    } catch (err) {
      setError("Invalid credentials. Please try again.");
    }
  };

  return (
    <div className="page-container">
      {/* Header */}
      <header className="header">
        <div className="logo-container">
          <img src={logo} alt="Elite Academy Logo" className="logo" />
          <span className="logo-text">Elite Academy</span>
        </div>

        <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <nav className={`nav-links ${menuOpen ? "open" : ""}`}>
          <a href="/">Home</a>
          <a href="/about">About Us</a>
          <a href="/features">Features</a>
          <a href="/contact">Contact Us</a>
        </nav>

        <div className="header-right">
          <a href="tel:+917330643345" className="phone-btn">
            <FaPhone /> +91 7330643345
          </a>
        </div>
      </header>

      {/* Login Section with Background */}
      <main className="login-section">
        <div className="login-container">
          <h2>Login to Your Account</h2>
          {error && <p className="error-text">{error}</p>}
          <form onSubmit={handleSubmit}>
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label>Password</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />

            <button type="submit">
              <FaSignInAlt /> Login
            </button>
          </form>
        </div>
      </main>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Elite Academy. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default Login;
