import React, { useState } from 'react';
import { FaSignOutAlt, FaBars, FaTimes } from 'react-icons/fa';
import './Dashboard.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Dashboard = () => {
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const programs = {
    'Tech Olympiad': ['Class I', 'Class II', 'Class III', 'Class IV', 'Class V'],
    'Techno': ['Class VI', 'Class VII', 'Class VIII', 'Class IX', 'Class X'],
    'Olympiad': ['Class VI', 'Class VII', 'Class VIII', 'Class IX', 'Class X']
  };

  const handleProgramClick = (program) => {
    setSelectedProgram(program === selectedProgram ? null : program);
    setSelectedClass(null);
  };

  const handleClassClick = (cls) => {
    setSelectedClass(cls);
  };

  const handleLogout = () => {
    window.location.href = '/';
  };

  return (
    <div className="dashboard-container">
      
      {/* Header Section */}
      <header className="header-container">
        <div className="logo">Admin Dashboard ✅</div>
        <nav className={`nav-links ${isMenuOpen ? 'nav-active' : ''}`}>
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#programmes">Programmes</a>
          <a href="#contact">Contact</a>
          <button className="logout-button-header" onClick={handleLogout}>
            <FaSignOutAlt /> Logout
          </button>
        </nav>
        <div className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
      </header>

      {/* Main Body Container */}
      <div className="body-container">
        {/* Sidebar */}
        <div className="sidebar">
          {Object.keys(programs).map((program) => (
            <div key={program}>
              <button 
                className="program-button" 
                onClick={() => handleProgramClick(program)}
              >
                {program} {selectedProgram === program ? '▼' : '▶'}
              </button>
              {selectedProgram === program && (
                <div className="class-list">
                  {programs[program].map((cls) => (
                    <button 
                      key={cls} 
                      className="class-button" 
                      onClick={() => handleClassClick(cls)}
                    >
                      {cls}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Main Content */}
        <div className="main-content">
          {selectedClass && (
            <>
              <h2 className="header-title">{selectedProgram} ➝ {selectedClass}</h2>
              <div className="content-buttons">
                <button className="button button1">📅 Micro Schedule : 1</button>
                <button className="button button2">📄 Work Sheet : 0</button>
                <button className="button button3">📝 Examinations : 1</button>
                <button className="button button4">📊 Analysis : 0</button>
                <button className="button button5">🔍 Solutions : 0</button>
              </div>
            </>
          )}
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
